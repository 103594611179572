import React, { useState } from "react";
import Navbar from "./navbar";
import Footer from "./footer";
import Menu from "../menu";

type LayoutProps = {
  children: React.ReactNode;
};

const Index = ({ children }: LayoutProps) => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <div className=" relative flex flex-col items-center justify-center h-auto w-full bg-background ">
      <Menu showMenu={showMenu} setShowMenu={setShowMenu} />
      <Navbar showMenu={showMenu} setShowMenu={setShowMenu} />
      <div className=" w-full font-spaceGrotesk bg-background ">{children}</div>
      <Footer />
    </div>
  );
};

export default Index;
