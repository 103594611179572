import React from "react";
import { motion } from "framer-motion";

const ServiceCard = ({ info }: any) => {
  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };
  return (
    <motion.div
      variants={item}
      className={` relative flex flex-col items-start justify-center font-outfit h-[190px] w-full lg:w-[320px] rounded-[6px] border border-green shadow-blue mr-[20px] p-[18px] shadow-md text-white bg-chacoblack `}
    >
      <img className=" h-[45px] " src={info.icon} alt="icons" />
      <h1 className={` text-left text-[16px] font-extralight mt-[10px] `}>
        {info.title}
      </h1>
      <p
        className={` font-PlusJakartaSans font-light text-offwhite/40 text-[12px] mt-[10px] text-left `}
      >
        {info.text}
      </p>
    </motion.div>
  );
};

export default ServiceCard;
