import React from "react";
import { MdOutlineCancel } from "react-icons/md";
import { motion } from "framer-motion";

const Menu = ({ showMenu, setShowMenu }: any) => {
  const closeMenu = () => {
    setShowMenu(false);
  };
  return (
    <>
      {showMenu && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: showMenu ? 1 : 0 }}
          transition={{ duration: 0.3 }}
          className=" flex flex-col items-center justify-center fixed top-0 z-50 h-full w-full backdrop-blur-md bg-black/50 py-6 "
        >
          <button
            onClick={() => {
              setShowMenu(false);
            }}
            className=" absolute top-[25px] text-[30px] right-[23px]  text-white "
          >
            <MdOutlineCancel fontSize="inherit" />
          </button>
          <div className=" flex flex-col items-center text-white text-lg font-semibold ">
            <a
              onClick={closeMenu}
              href="#home"
              className=" text-[14px] mb-[10px] "
            >
              Home
            </a>
            <a
              onClick={closeMenu}
              href="#about"
              className=" text-[14px] my-[10px] "
            >
              About
            </a>
            <a
              onClick={closeMenu}
              href="#ourservices"
              className=" text-[14px] my-[10px] "
            >
              Services
            </a>
            {/* <a
              onClick={closeMenu}
              href="#portfolio"
              className=" text-[14px] my-[10px] "
            >
              Portfolio
            </a> */}
            <a
              onClick={closeMenu}
              href="#testimonials"
              className=" text-[14px] my-[10px] "
            >
              Testimonial
            </a>
          </div>
          <a
            href="https://t.me/oxQuantum"
            target="_blank"
            rel="noreferrer"
            className=" flex items-center justify-center h-[50px] w-[200px] text-black font-medium bg-green hover:bg-green/80 duration-200 mt-[20px] "
          >
            Get free consultation
          </a>
        </motion.div>
      )}
    </>
  );
};

export default Menu;
