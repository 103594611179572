import React from "react";
import frame1 from "../../assets/images/frame1.png";
import frame2 from "../../assets/images/frame2.png";
import image1 from "../../assets/images/Image1.png";

const Header = () => {
  return (
    <div
      id="home"
      className=" relative flex flex-col items-center justify-center h-[80vh] lg:h-screen w-full bg-chacoblack "
    >
      <img
        className=" z-30 absolute left-0 h-[50%] md:h-[80%] "
        src={frame1}
        alt="leftframe"
      />
      <div className=" flex flex-col items-center font-light mt-[20px] lg:mt-0 ">
        <h2 className=" text-center text-3xl md:text-[50px] leading-snug lg:leading-[55px] text-white  font-semibold ">
          Welcome to <span className=" text-green ">OxQuantum!</span>
        </h2>
        <p className=" text-center text-[16px] font-outfit font-extralight text-offwhite mt-[20px] md:mt-[20px] px-[20px] ">
          Your gateway to AI-driven solutions, decentralized applications, and
          secure infrastructure.
          <br className="hidden md:inline-flex" />
          We build OpenAI technologies, Web3 applications, Smart contract
          <br className="hidden md:inline-flex" />
          and Seamless server DevOps
        </p>
        {/* <p className=" text-center text-[16px] font-outfit font-extralight text-offwhite mt-[20px] md:mt-[20px] px-[20px] ">
          Your gateway to Web3 and blockchain innovations, revolutionizing{" "}
          <br className="hidden md:inline-flex" />
          industries through cutting-edge technology{" "}
          <br className="hidden md:inline-flex" />
          and secure solutions.
        </p> */}
      </div>
      <img
        className=" z-30 absolute right-0 h-[30%] md:h-[60%] "
        src={frame2}
        alt="leftframe"
      />
      <img
        className=" z-30 absolute bottom-0 h-[20%] md:h-[30%] "
        src={image1}
        alt="leftframe"
      />
    </div>
  );
};

export default Header;
