import React from "react";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import logo from "../../assets/logo/oxquantum.png";
// import logoIcon from "../../assets/logo/oxquantum_icon.png";

const Navbar = ({ setShowMenu }: any) => {
  return (
    <div
      className={` fixed top-0 z-40 flex flex-col items-center h-auto w-full bg-chacoblack `}
    >
      <div className=" flex items-center justify-between h-[80px] w-[90%] md:w-[700px] lg:w-[1200px] text-offwhite font-outfit ">
        <a href="/" className=" flex items-center ">
          <img
            className=" h-[30px] md:h-[40px] mr-[0px] "
            src={logo}
            alt="logo"
          />
        </a>
        <button
          onClick={() => setShowMenu(true)}
          className=" inline-flex lg:hidden focus:outline-none "
        >
          <MenuOutlinedIcon fontSize="large" />
        </button>
        <div className=" font-light items-center hidden lg:inline-flex ">
          <a href="#home" className=" text-[14px] mr-[15px] ">
            Home
          </a>
          <a href="#about" className=" text-[14px] mx-[15px] ">
            About
          </a>
          <a href="#ourservices" className=" text-[14px] mx-[15px] ">
            Services
          </a>
          {/* <a href="#portfolio" className=" text-[14px] mx-[15px] ">
            Portfolio
          </a> */}
          <a href="#testimonials" className=" text-[14px] mx-[15px] ">
            Testimonial
          </a>

          {/* <a href="/" className=" text-[14px] mx-[15px] ">
            Blog
          </a> */}
        </div>
        <a
          href="https://t.me/oxQuantum"
          target="_blank"
          rel="noreferrer"
          className=" hidden lg:inline-flex items-center justify-center h-[50px] w-[200px] text-black bg-green hover:bg-green/80 duration-200 "
        >
          Get free consultation
        </a>
      </div>
    </div>
  );
};

export default Navbar;
