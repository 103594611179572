import React from "react";
import frame1 from "../../assets/images/frame1.png";
import Ethereum from "../../assets/logo/ethereum.png";
import Cosmos from "../../assets/logo/cosmos.png";
import Solana from "../../assets/logo/solana.png";
import Nodejs from "../../assets/logo/nodejs.png";
import Reactjs from "../../assets/logo/reactjs.png";
import Github from "../../assets/logo/github.png";
import Truffle from "../../assets/logo/truffle.png";
import Golang from "../../assets/logo/golang.png";
import AWS from "../../assets/logo/aws.png";
import maxxLogo from "../../assets/logo/maxxchainlogo.png";

const ToolsAndPlatforms = () => {
  return (
    <div
      id="ourservices"
      className=" relative flex flex-col items-center justify-center h-auto w-full  font-poppins bg-chacoblack "
    >
      <img
        className=" z-0 absolute hidden md:inline-flex right-0 bottom-0 rotate-180 h-[50%] md:h-[80%] "
        src={frame1}
        alt="leftframe"
      />
      <div className=" z-20 relative flex flex-col items-center w-[90%] md:w-[700px] lg:w-[1200px]  my-[120px] md:my-[140px] lg:my-[100px] ">
        <div className=" flex flex-col items-center text-black font-light ">
          <h2 className=" text-center text-xl leading-snug lg:leading-[55px] text-white  font-medium   font-spaceGrotesk ">
            Tools And <span className=" text-green ">Platforms</span>
          </h2>
          <p className=" text-center text-offwhite text-[16px] font-outfit mt-[4px] ">
            We Work Majorly With Over 200+ Development Tools And Platforms
          </p>
          <div className=" flex flex-col items-center w-[80%] md:w-[700px] lg:w-auto py-[40px] ">
            <div className=" grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-[30px] ">
              <img
                className=" h-[35px] md:h-[40px] "
                src={Ethereum}
                alt="Ethereum"
              />
              <img
                className=" h-[35px] md:h-[40px] "
                src={Cosmos}
                alt="Cosmos"
              />
              <img
                className=" h-[35px] md:h-[40px] "
                src={Solana}
                alt="Solana"
              />
              <img
                className=" h-[35px] md:h-[40px] "
                src={maxxLogo}
                alt="Remix"
              />
              <img
                className=" h-[35px] md:h-[40px] "
                src={Nodejs}
                alt="Nodejs"
              />
              <img
                className=" h-[35px] md:h-[40px] "
                src={Reactjs}
                alt="Reactjs"
              />
              <img
                className=" h-[35px] md:h-[40px] "
                src={Github}
                alt="Github"
              />
              <img
                className=" h-[35px] md:h-[40px] "
                src={Truffle}
                alt="Truffle"
              />
              <img
                className=" h-[35px] md:h-[40px] "
                src={Golang}
                alt="Golang"
              />
              <img className=" h-[35px] md:h-[40px] " src={AWS} alt="AWS" />
            </div>
          </div>
        </div>{" "}
      </div>
    </div>
  );
};

export default ToolsAndPlatforms;
