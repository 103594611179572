import React from "react";
import vector1 from "../../assets/images/vector1.png";
import light from "../../assets/images/light1.png";

const ValuedClients = () => {
  const clients = [
    { name: "dclabs", image: "/assets/clients/dc-labs.jpeg" },
    { name: "maxxchain", image: "/assets/clients/maxxchainlogo.png" },
    { name: "safumaxx", image: "/assets/clients/safumaxx.png" },
  ];

  return (
    <div className=" relative h-auto md:h-[200px] lg:h-[200px] w-full flex flex-col items-center justify-center text-offwhite bg-[#0E0E0F] py-[50px] md:py-0 overflow-hidden ">
      <img
        className=" inline-flex absolute left-[-150px] md:left-0 h-full "
        src={vector1}
        alt="logo"
      />
      <h1 className=" text-xl font-medium font-spaceGrotesk  ">
        Valued <span className=" text-green ">Clients</span>
      </h1>
      <div className="z-20 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 mt-[25px] ">
        {clients.map((client) => (
          <img className=" h-[40px] " src={client.image} alt={client.name} />
        ))}
      </div>
      <img
        className="z-20 absolute right-0 bottom-[-500px] h-[800px] rotate-180 "
        src={light}
        alt="logo"
      />
      <img
        className=" hidden md:inline-flex absolute right-0 rotate-180 h-full "
        src={vector1}
        alt="logo"
      />
    </div>
  );
};

export default ValuedClients;
