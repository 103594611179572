import React from "react";
import { motion } from "framer-motion";

const WorkProcessCard = ({ process }: any) => {
  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  return (
    <motion.div
      variants={item}
      className={` flex ${
        process.id === 2 ? " flex-row md:flex-row-reverse " : "flex-row"
      } items-start md:items-center justify-between h-auto w-full lg:w-[80%] rounded-[10px] md:rounded-full bg-[#19191B] p-[25px] md:p-[45px] my-[20px] md:my-[14px] `}
    >
      <div
        className={` flex flex-col ${
          process.id === 2 ? "  md:flex-row-reverse " : " md:flex-row"
        } items-start md:items-center `}
      >
        <img
          className=" h-[45px] md:h-[65px] lg:h-[60px] "
          src={process.icon}
          alt="numbers"
        />
        <div
          className={` flex flex-col text-sm font-outfit ml-0 md:ml-[20px] mt-[20px] md:mt-0 ${
            process.id === 2
              ? " md:mr-[20px] md:text-right  items-start md:items-end "
              : " md:mr-0 md:text-left "
          } `}
        >
          <h1 className=" text-lg font-spaceGrotesk ">{process?.title}</h1>
          <p className={` w-full md:w-[50%] text-offwhite/80 text-xs `}>
            {process?.description}
          </p>
        </div>
      </div>
      <div className=" w-[50%] md:w-auto ">
        <img
          className=" h-[45px] md:h-[65px] lg:h-[60px] "
          src={process.image}
          alt="icon"
        />
      </div>
    </motion.div>
  );
};

export default WorkProcessCard;
