import React from "react";
import { BiRightArrowAlt } from "react-icons/bi";
import { motion } from "framer-motion";

const ProjectCard = ({ item }: any) => {
  const variation = {
    hidden: { x: 20, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
    },
  };

  return (
    <motion.div
      variants={variation}
      style={{ backgroundImage: `url(${item.image})` }}
      className=" flex flex-col items-center justify-end h-[340px] md:h-[310px] lg:h-[360px] w-[340px] md:w-[330px] lg:w-[380px] rounded-[14px] border-2 border-green bg-gray-200 bg-cover bg-no-repeat bg-center p-[15px] "
    >
      <a href={item.link} target="_blank" rel="noreferrer">
        <div className=" flex items-center justify-between h-auto w-full bg-black/70 backdrop-blur-[2px] rounded-[8px] p-[12px] ">
          <div className=" flex flex-col text-xs font-medium ">
            <h1 className=" text-sm ">{item.name}</h1>
            <p className=" mt-[5px] font-light ">{item.desc}</p>
          </div>
          <div>
            <div className=" flex items-center justify-center h-[30px] w-[30px] text-black rounded-full bg-green ml-[10px] ">
              <BiRightArrowAlt fontSize="large" />
            </div>
          </div>
        </div>
      </a>
    </motion.div>
  );
};

export default ProjectCard;
