import React, { useRef, useState } from "react";
import { BiLogoTelegram } from "react-icons/bi";
import { MdEmail } from "react-icons/md";
import { BsTwitter } from "react-icons/bs";
import emailjs from "@emailjs/browser";
// import { TfiLinkedin } from "react-icons/tfi";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

interface FormData {
  user_name: string;
  user_email: string;
  message: string;
}

const Footer = () => {
  // const currentYear = new Date().getFullYear();
  const notify = () => toast.success("Message sent 👌");

  const [formData, setFormData] = useState<FormData>({
    user_name: "",
    user_email: "",
    message: "",
  });

  const [errorMessage, setErrorMessage] = useState<string>("");

  const onChange = (field: keyof FormData, value: string) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const form = useRef<HTMLFormElement>(null);

  const sendEmail = (e: React.FormEvent) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_c3l9l8g",
        "template_pkwybug",
        form.current!,
        "vT4tLYHP6DwxeBZaP"
      )
      .then((result) => {
        console.log(result.text);
      })
      .catch((error) => {
        console.log(error.text);
      });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (formData.user_name && formData.user_email && formData.message) {
      setErrorMessage("");
      sendEmail(e);
      setTimeout(() => {
        setFormData({
          user_name: "",
          user_email: "",
          message: "",
        });
        notify();
      }, 2000);
    } else {
      setErrorMessage("Please fill in all fields");
    }
  };

  return (
    <div
      id="footer"
      className=" flex flex-col items-center w-full font-poppins bg-[#0B0B0C] relative "
    >
      <ToastContainer theme="dark" />
      <div className=" w-full flex flex-col items-center ">
        <div className=" h-auto w-[90%] md:w-[90%] lg:w-[1200px] flex flex-col-reverse md:flex-row font-outfit bg-transparent ">
          <div className=" flex flex-col items-start w-full md:w-[50%] border-0 md:border-r-2 border-chacoblack ">
            <div className=" flex flex-col items-start w-full border-b-2 border-chacoblack py-[30px] ">
              <h1 className=" text-center text-lg leading-snug lg:leading-[55px] text-white font-medium font-spaceGrotesk ">
                Our <span className=" text-green ">Services</span>
              </h1>
              <ul className=" w-full grid grid-cols-2 gap-y-3 text-sm text-offwhite/70 font-light mt-[10px] md:mt-[10px] lg:mt-0 ">
                <li>
                  <a href="/#" className=" text-[14px] ">
                    - OpenAi Development / Integration
                  </a>
                </li>
                <li>
                  <a href="/#" className=" text-[14px] ">
                    - Web3 / Smart Contract development
                  </a>
                </li>
                <li>
                  <a href="/#" className=" text-[14px] ">
                    - Blockchain setup / cloning
                  </a>
                </li>
                <li>
                  <a href="/#" className=" text-[14px] ">
                    - Server setup / management
                  </a>
                </li>
                <li>
                  <a href="/#" className=" text-[14px] ">
                    - TON/Tap Game development
                  </a>
                </li>
                <li>
                  <a href="/#" className=" text-[14px] ">
                    - Mining pool setup / management
                  </a>
                </li>
              </ul>
            </div>
            <div className=" w-full flex flex-col items-start border-b-2 border-chacoblack py-[30px] ">
              <h1 className=" text-center text-lg leading-snug lg:leading-[55px] text-white font-medium font-spaceGrotesk ">
                Contact <span className=" text-green ">Us</span>
              </h1>
              <div className=" flex items-center text-sm text-offwhite/70 my-[10px] md:my-[10px] lg:my-0 ">
                <span className=" text-green  mr-[10px] ">
                  <MdEmail />
                </span>{" "}
                infoOxquantum@gmail.com
              </div>
              {/* <h1 className=" text-center text-lg leading-snug lg:leading-[55px] text-white font-medium font-spaceGrotesk ">
                Chat With <span className=" text-green ">Us</span>
              </h1>
              <a
                href="/#"
                target="_blank"
                rel="noreferrer"
                className=" flex items-center justify-center h-[35px] w-[35px] rounded-full text-chacoblack bg-green mt-[10px] md:mt-[10px] lg:mt-0 "
              >
                <BiSolidMessageRoundedDots fontSize="large" />
              </a> */}
            </div>
            <div className=" flex flex-col items-start py-[30px] ">
              <h1 className=" text-center text-lg leading-snug lg:leading-[55px] text-white font-medium font-spaceGrotesk ">
                Follow <span className=" text-green ">Us</span>
              </h1>
              <div className=" flex items-center mt-[10px] md:mt-[10px] lg:mt-0 ">
                <a
                  href="https://twitter.com/oxquantum"
                  target="_blank"
                  rel="noreferrer"
                  className=" flex items-center justify-center h-[35px] w-[35px] rounded-full text-chacoblack bg-green mr-[10px] "
                >
                  <BsTwitter fontSize="large" />
                </a>
                <a
                  href="https://t.me/oxQuantum"
                  target="_blank"
                  rel="noreferrer"
                  className=" flex items-center justify-center h-[35px] w-[35px] rounded-full text-chacoblack bg-green mr-[10px] "
                >
                  <BiLogoTelegram fontSize="large" />
                </a>
                {/* <a
                  href="/#"
                  target="_blank"
                  rel="noreferrer"
                  className=" flex items-center justify-center h-[35px] w-[35px] rounded-full text-chacoblack bg-green mr-[10px] "
                >
                  <TfiLinkedin fontSize="large" />
                </a> */}
              </div>
            </div>
          </div>
          <div className=" w-full md:w-[50%] flex flex-col items-start py-[30px] pl-0 md:pl-[30px] text-sm ">
            <h1 className=" text-center text-lg leading-snug lg:leading-[55px] text-white font-medium font-spaceGrotesk ">
              Get <span className=" text-green ">Quote</span>
            </h1>
            <p className=" text-offwhite/70 text-sm font-outfit mt-[4px] ">
              Fill out the form below and we will get in touch with you soon
            </p>

            {/* <form ref={form} onSubmit={sendEmail}>
              <label>Name</label>
              <input type="text" name="user_name" />
              <label>Email</label>
              <input type="email" name="user_email" />
              <label>Message</label>
              <textarea name="message" />
              <input type="submit" value="Send" />
            </form> */}

            <form className=" w-full " ref={form} onSubmit={handleSubmit}>
              <div className=" w-full md:w-[80%] flex flex-col items-center mt-[20px] ">
                <input
                  type="text"
                  name="user_name"
                  value={formData.user_name}
                  placeholder="Full Name"
                  onChange={(e) => onChange("user_name", e.target.value)}
                  className=" w-full focus:outline-none border border-offwhite/30 rounded-[3px] bg-transparent p-[6px] text-white placeholder:text-sm "
                />
                <input
                  type="text"
                  name="user_email"
                  value={formData.user_email}
                  placeholder="Email"
                  onChange={(e) => onChange("user_email", e.target.value)}
                  className=" w-full focus:outline-none border border-offwhite/30 rounded-[3px] bg-transparent p-[6px] text-white placeholder:text-sm mt-[20px] "
                />
              </div>
              <textarea
                name="message"
                placeholder="How can we help you?"
                value={formData.message}
                onChange={(e) => onChange("message", e.target.value)}
                className=" h-[120px] w-full md:w-[80%] focus:outline-none border border-offwhite/30 rounded-[3px] bg-transparent p-[6px] text-white  placeholder:text-sm mt-[20px] "
              />
              <p className=" text-sm text-red-500 ">
                {errorMessage && errorMessage}
              </p>
              <button
                // onClick={() => {

                // }}
                // type="submit"
                // value="Send"
                className=" h-[35px] w-[120px] bg-green hover:bg-green/80 duration-300 mt-[20px] "
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
